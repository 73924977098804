var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"course",staticStyle:{"min-width":"800px"},attrs:{"id":"course-modal","title":"Tạo khoá học mới"},on:{"show":_vm.openStaffPopup,"hidden":_vm.resetModal,"ok":_vm.submit},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"btn btn-success ml-3",attrs:{"type":"submit"},on:{"click":_vm.submit}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/save.svg"}})],1),_vm._v(" Lưu ")])],1)]},proxy:true}])},[(_vm.rendered)?_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('form',{ref:"form-group",on:{"submit":_vm.submit}},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('basic-input',{attrs:{"label":"Tên khoá học","placeholder":"Nhập tên khoá học","name":"courseName","value":_vm.filter.name,"required":true},on:{"update:value":function($event){return _vm.$set(_vm.filter, "name", $event)}}})],1)],1),_c('b-row',[_c('b-col',[_c('basic-text-area',{attrs:{"label":"Mục tiêu khoá học ","placeholder":"Nhập miêu tả cho mục tiêu khoá học ","name":"description","value":_vm.filter.description},on:{"update:value":function($event){return _vm.$set(_vm.filter, "description", $event)}}})],1)],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"label-required"},[_vm._v("Cài đặt hiển thị khoá học trên App & theo gói dịch vụ ")]),_c('b-col',{staticClass:"pl-0"},[_c('basic-check-box',{staticClass:"pb-1",attrs:{"options":[
                    { text: 'Hiển thị khoá học trên App', value: false },
                  ],"value":_vm.filter.isActive},on:{"update:value":function($event){return _vm.$set(_vm.filter, "isActive", $event)}}}),_c('basic-check-box',{staticClass:"pb-1",attrs:{"options":[
                    {
                      text: 'Chỉ hiển thị khoá học cho thành viên Cơ Bản',
                      value: false,
                    },
                  ],"value":_vm.filter.isActive},on:{"update:value":function($event){return _vm.$set(_vm.filter, "isActive", $event)}}}),_c('basic-check-box',{staticClass:"pb-1",attrs:{"options":[
                    {
                      text: 'Chỉ hiển thị khoá học cho thành viên Bạc',
                      value: false,
                    },
                  ],"value":_vm.filter.isActive},on:{"update:value":function($event){return _vm.$set(_vm.filter, "isActive", $event)}}}),_c('basic-check-box',{staticClass:"pb-1",attrs:{"options":[
                    {
                      text: 'Chỉ hiển thị khoá học cho thành viên Vàng',
                      value: false,
                    },
                  ],"value":_vm.filter.isActive},on:{"update:value":function($event){return _vm.$set(_vm.filter, "isActive", $event)}}})],1)],1)],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"py-0"},[_c('label',{staticClass:"mb-0",attrs:{"for":"sendDate"}},[_vm._v("Thơì gian hiệu lực")])])],1),_c('b-row',[_c('b-col',[_c('basic-date-picker',{attrs:{"placeholder":"Từ ngày","name":"startDateTimeToSend"}})],1),_c('b-col',[_c('basic-date-picker',{attrs:{"placeholder":"Đến ngày","name":"endDateTimeToSend"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"picture-input-container"},[_c('picture-input',{ref:"pictureInput",attrs:{"width":"228","height":"128","margin":"16","accept":"image/jpeg,image/png","size":"16","buttonClass":"btn btn-success","crop":false,"removable":true,"customStrings":_vm.customStrings}}),_c('div',{staticClass:"mt-3 text-center"},[_c('span',[_vm._v("Hình ảnh khoá học, tỷ lệ 16:9")])])],1)])],1)],1)],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }